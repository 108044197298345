.wysiwyg {
    p, li {
        margin-bottom: calc((24 / 18) * 1em);

        @screen l {
            margin-bottom: calc((28 / 20) * 1em);
        }

        @screen xl {
            margin-bottom: calc((32 / 22) * 1em);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    blockquote:last-child {
        margin-bottom: 0;
    }

    b, strong {
        font-weight: bold;
    }

    i, em {
        font-style: italic;
    }

    a {
        @apply underline hover:no-underline;
    }

    h2 {
        @apply h2 mb-8 m:mb-10;
    }

    h3 {
        @apply h4 mb-8;
    }

    h2, h3 {
        @apply mt-48 m:mt-64 first:mt-0;
    }

    ul, ol {
        margin: 24px 0;

        &:last-child {
            margin-bottom: 0;
        }

        @screen m {
            margin: 32px 0;
        }
        @screen l {
            margin: 40px 0;
        }

        li {
            padding-left: 40px;
            position: relative;

            &::before {
                content: "";
                display: block;
                width: 10px;
                height: 10px;
                border-radius: 5px;
                position: absolute;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    ul li::before {
        top: 0.4em;
        left: 14px;
        background-color: theme(colors.yellow.DEFAULT);
    }

    ol {
        counter-reset: list_counter;
    }

    ol li::before {
        top: 0;
        left: 0;
        text-align: right;
        width: 30px;
        color: theme(colors.bb.5);
        counter-increment: list_counter;
        content: counter(list_counter) '.';
    }

    ul > li > ul,
    ol > li > ol {
        margin: 1.5em 0;
    }

}
