.page-header {
    
    &__bar-bg {
        transition-property: opacity, background-color;
        transition-duration: 300ms, 600ms;
        transition-delay: 200ms, 0ms;
        transition-timing-function: linear, theme('transitionTimingFunction.out-sine');


        .is-transparent:not(.is-locked) & {
            opacity: 0;
        }

        .is-hidden:not(.is-locked) & {

        }

        .is-locked & {

        }

        .is-forced & {
            transition-duration: 0ms !important;
            transition-delay: 0ms !important;
        }
    }

    &__bar {
        transition-property: transform;
        transition-duration: 400ms;
        transition-delay: 0ms;
        transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);

        .is-transparent:not(.is-locked) & {

        }

        .is-hidden:not(.is-locked) & {
            transform: translate(0, -101%);
            transition-duration: 250ms;
        }

        .is-locked & {
            transition-delay: 0ms;
        }

        .is-forced & {
            transition-duration: 0ms !important;
            transition-delay: 0ms !important;
        }
    }
}
