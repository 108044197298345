@layer components {

    /* Text styles */
    .h1 {
        @apply text-32 leading-40 m:text-36 m:leading-44 l:text-48 l:leading-56 xl:text-56 xl:leading-64;
    }
    .h1-portrait {
        @apply h1 italic;
    }
    .h2 {
        @apply text-24 leading-32 l:text-28 l:leading-36 xl:text-32 xl:leading-42;
    }
    .h3 {
        @apply text-19 leading-22 l:text-24 l:leading-28 xl:text-26 xl:leading-32;
    }
    .h4 {
        @apply text-16 leading-20 l:text-20 l:leading-24 font-bold;
    }
    .h5 {
        @apply text-14 leading-17 l:text-18 l:leading-24 font-medium;
    }
    .intro {
        @apply text-22 leading-28 l:text-24 l:leading-32 xl:text-28 xl:leading-36 font-medium;
    }
    .p {
        @apply text-18 leading-24 l:text-20 l:leading-28 xl:text-22 xl:leading-32;
    }
    .fakta {
        @apply text-16 leading-20 l:text-18 l:leading-22 xl:text-20 xl:leading-24;
    }
    .small {
        @apply text-14 leading-17 l:text-16 l:leading-20 xl:text-18 xl:leading-24;
    }
    
    .top-padding {
        @apply pt-103 m:pt-160 l:pt-200;
    }
    
    .top-bar-padding {
        @apply pt-60 m:pt-80 l:pt-110;
    }
    
    .hit {
        &::before {
            content: "";
            display: block;
            width: 100%;
            height: 100%;
            min-width: 44px;
            min-height: 44px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
    
    .toggle-indicator {
        width: 24px;
        height: 24px;
        display: block;
        
        &:before,
        &:after {
            content: '';
            display: block;
            background: currentColor;
            position: absolute;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
        }
        
        &:before {
            width: 2px;
            height: 9px;
            transition: height 0.2s ease;
            button[aria-expanded="true"] & {
                height: 2px;
            }
        }
        &:after {
            width: 9px;
            height: 2px;
        }
    }
    
    .grapper {
        @apply wrapper grid gutters-x;
    }
    
    .variable-ratio {
        padding-bottom: calc((1/var(--ratio-s))*100%);
        
        @screen m {
            padding-bottom: calc((1/var(--ratio-m, --ratio-s))*100%);
        }
        @screen l {
            padding-bottom: calc((1/var(--ratio-l, --ratio-m))*100%);
        }
    }
    
    .contact-pill {
        @apply inline-block py-8 px-16 l:px-24 rounded-[20px];
        
        .is-case-small & {
            @apply bg-transparent border-current border-1;
        }
    }
    
    .map-marker {
        @apply bg-orange-signal size-[20px] rounded-10px;
    }
    
    .search-clear-button {
        @screen m {
            margin-right: 32px;
            position: relative;
            
            &:after {
                content: '';
                display: block;
                height: 16px;
                width: 1px;
                background-color: theme('colors.bb.3');
                position: absolute;
                right: -16px;
                top: 18px;
            }
        }
    }
    
    /* Forms */
    .input {
        @apply small w-full bg-white border-2 border-white !rounded-[4px] placeholder-[rgb(152,127,97)] p-16 l:px-18 transition-all duration-300;
        outline: none;
        
        &:active,
        &:focus {
            @apply border-bb-5
        }
        
        .has-errors & {
            @apply border-red;
        }
    }
    
    .checkbox {
        input {
            appearance: none;
            width: 0;
            height: 0;
            display: block;
        }

        input:focus {
            outline: 0;

            + .checkbox__inner {
                outline: 2px solid currentColor;
                outline-offset: 1px;
            }
        }

        &__inner {
            user-select: none;
        }
    }

    .checkbox {
        &__bullet {

        }

        input[type="checkbox"]:checked + .checkbox__inner .checkbox__bullet-inner {
            opacity: 100;
        }
    }
    
}
