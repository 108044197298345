.btn {
    @apply inline-block border-1 rounded-[8px] transition-colors duration-200;
    
    &--small {
        @apply fakta py-7 l:pt-9 l:pb-7 px-18;
    }
    &--large {
        @apply fakta pt-12 pb-10 px-18;
    }
    
    &--primary {
        @apply border-yellow bg-yellow text-black;
        
        &:hover {
            @apply border-yellow-signal bg-yellow-signal;
        }
    }
    &--secondary {
        @apply border-current bg-transparent;
        
        &:hover {
            @apply border-yellow bg-yellow;
        }
    }
    &--theme-aware {
        .theme-bb-1 &,
        .theme-bb-2 &,
        .theme-bb-3 &,
        .theme-bb-4 & {
            @apply border-yellow bg-yellow text-black;
        
            &:hover {
                @apply border-yellow-signal bg-yellow-signal;
            }
        }
        .theme-bb-7 &,
        .theme-yellow &,
        .theme-green &,
        .theme-terracotta & {
            @apply border-current bg-transparent;
        
            &:hover {
                @apply border-yellow bg-yellow text-black;
            }
        } 
    }
}
